import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const SoliciteSection = styled.section`
  background-color: ${grayscale[500]};
  min-height: 690px;

  @media ${device.tablet} {
    min-height: 634px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-inter-black-small/image.png');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center right -40px;
  }

  @media ${device.desktopLG} {
    min-height: 674px;
    background-image: none;
  }

  @media ${device.desktopXL} {
    min-height: 708px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-inter-black/image.png');
    background-size: initial;
    background-repeat: no-repeat;
    background-position: center right;
  }

  button, .btn {
    height: 48px;
    padding-top: 7px;
  }
`
