import React from 'react'
import ClientReview from '../../components/ClientReview/_index'
import clientReviewData from './clientReviewData.json'
import useWidth from 'src/hooks/window/useWidth'

import FiveStars from '../../assets/images/fivestars.png'
import { DuoLoverrSection } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface IClienteData {
  clientName: string;
  imageSource: string;
  reviewText: string;
}
interface IProps {
  openModal: () => void;
}

const WIDTH_TABLET = 720

const DuoLovers = ({ openModal }: IProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const oneLink = 'https://inter-co.onelink.me/Qyu7/jh4wryck'

  return (
    <DuoLoverrSection>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center justify-content-lg-between'>
          <div className='col-12 col-md-8 col-lg-6'>
            <img src={FiveStars} alt='' width={120} height={24} />
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 pt-2 mb-lg-4'>O que dizem os <br />DuoLovers</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--400 pt-lg-1'>
              Gastronomia vai muito além de comer bem. São experiências que se compartilha, sabores que se descobre e lugares novos que se conhece.
            </p>
            <div className='d-none d-lg-block mt-4'>
              <button
                className='btn text-white btn-orange--extra text-none fw-700'
                title='Quero ser um Duo Lover'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    section_name: 'O que dizem os DuoLovers',
                    element_action: 'click button',
                    element_name: ' Quero compartilhar experiências',
                  })
                }}
              >
                Quero ser um Duo Lover
              </button>
            </div>
          </div>
          <div className='col-12 col-md-8 col-lg-6 col-xl-5'>
            {clientReviewData.map((cliente: IClienteData) => (
              <div className='mt-4' key={cliente.clientName}>
                <ClientReview clientName={cliente.clientName} imageSource={cliente.imageSource} reviewText={cliente.reviewText} />
              </div>
            ))}
          </div>
          <div className='d-block d-lg-none mt-4'>
            {width > WIDTH_TABLET ? (
              <button
                className='btn text-white btn-orange--extra text-none'
                title='Quero ser um Duo Lover'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    section_name: 'O que dizem os DuoLovers',
                    element_action: 'click button',
                    element_name: 'Quero ser Inter Duo Gourmet',
                  })
                }}
              >
                Quero ser um Duo Lover
              </button>
            ) : (
              <a
                href={oneLink}
                target='_blank'
                rel='noreferrer'
                className='btn text-white btn-orange--extra text-none'
                title='Quero ser um Duo Lover'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_08',
                    section_name: 'O que dizem os DuoLovers',
                    element_action: 'click button',
                    element_name: 'Quero ser Inter Duo Gourmet',
                    redirect_url: oneLink,
                  })
                }}
              >
                Quero ser um Duo Lover
              </a>
            )}
          </div>
        </div>
      </div>
    </DuoLoverrSection>
  )
}

export default DuoLovers
