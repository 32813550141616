import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'

import usePageQuery from '../../pageQuery'

import { SoliciteSection, CardContainer } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const listData = [
  {
    image: 'nordesteDuo',
    text: 'Nordeste',
    subText: 'Melhores restaurantes de Salvador e mais cidades.',
    link: 'https://inter-co.onelink.me/Qyu7/u4v3s0pz',
  },
  {
    image: 'sudesteDuo',
    text: 'Sudeste',
    subText: 'Melhores restaurantes de SP, BH, RJ e mais cidades.',
    link: 'https://inter-co.onelink.me/Qyu7/k3z3l5an',
  },
  {
    image: 'norteDuo',
    text: 'Norte',
    subText: 'Melhores restaurantes de Belém e mais cidades.',
    link: 'https://inter-co.onelink.me/Qyu7/1g80u6iw',
  },
  {
    image: 'centroOesteDuo',
    text: 'Centro-Oeste',
    subText: 'Melhores restaurantes de Brasília e mais cidades.',
    link: 'https://inter-co.onelink.me/Qyu7/yp2lyctg',
  },
  {
    image: 'sulDuo',
    text: 'Sul',
    subText: 'Melhores restaurantes de Curitiba e mais cidades.',
    link: ' https://inter-co.onelink.me/Qyu7/asos47qp',
  },
]

interface IItemData {
  image: string;
  text: string;
  subText: string;
  link: string;
}

interface ISoliciteSeuCartao {
  openModal: () => void;

}

const RestaurantesPerto = ({ openModal }: ISoliciteSeuCartao) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const oneLink = 'https://inter-co.onelink.me/Qyu7/jh4wryck'

  return (
    <SoliciteSection className='py-5 position-relative d-md-flex justify-content-center align-items-md-center'>
      <div className='container'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center pb-3'>
              Restaurantes perto de mim? No Duo tem!
            </h2>

            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400 text-center mb-4 pb-2'>
              Estamos presentes em todas as regiões do Brasil e também nos Estados Unidos.
              Tenha na palma da mão um guia dos
              <strong> melhores bares e restaurantes, além de cafeterias e sorveterias </strong>
              mais próximas de você, onde você estiver!
            </p>

            <div className='d-flex flex-wrap justify-content-center cards-container'>
              {listData.map((item: IItemData, index: number) => (
                <CardContainer
                  key={index}
                  href={item.link}
                  target='blank'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Restaurantes perto de mim? No Duo tem!',
                      element_action: 'click button',
                      element_name: item.text,
                      redirect_url: item.link,
                    })
                  }}
                >
                  <div className='d-flex'>
                    <Img fluid={data[item.image].fluid} />
                    <div className='ml-3'>
                      <p className='fs-20 lh-25 fw-700 text-grayscale--500 mb-0'>{item.text}</p>
                      <p className='fs-14 lh-17 text-grayscale--400'>{item.subText}</p>
                    </div>
                  </div>
                </CardContainer>
              ))}
            </div>

            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--400 text-center mb-4 mt-md-3'>
              Uma única assinatura é válida em milhares de cidades do Brasil e também nos Estados Unidos.
            </p>

            {width > WIDTH_MD ? (
              <div className='d-flex align-items-center justify-content-center'>
                <button
                  className='btn text-white btn-orange--extra text-none mt-2'
                  title='Economizar com o Duo'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Restaurantes perto de mim? No Duo tem!',
                      element_action: 'click button',
                      element_name: 'Economizar com o Duo',
                    })
                  }}
                >
                  Economizar com o Duo
                </button>
              </div>
            ) : (
              <div className='d-flex align-items-center justify-content-center'>
                <a
                  href={oneLink}
                  target='_blank'
                  rel='noreferrer'
                  className='btn text-white btn-orange--extra text-none mt-2'
                  title='Economizar com o Duo'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_07',
                      section_name: 'Restaurantes perto de mim? No Duo tem!',
                      element_action: 'click button',
                      element_name: 'Economizar com o Duo',
                      redirect_url: oneLink,
                    })
                  }}
                >
                  Economizar com o Duo
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </SoliciteSection>
  )
}

export default RestaurantesPerto
