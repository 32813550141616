import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import cardsData from './cardData.json'
import useWidth from 'src/hooks/window/useWidth'

import { VantagensSection, CardStyle } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
interface ICardDataType {
  icon: string;
  title: string;
  description: string;
}
interface IProps {
  openModal: () => void;
}

const WIDTH_MD = 720

const Vantagens = ({ openModal }: IProps) => {
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  const oneLink = 'https://inter-co.onelink.me/Qyu7/jh4wryck'

  return (
    <VantagensSection>
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 text-center'>
              Vantagens do Duo Gourmet
            </h2>
          </div>
        </div>
        <div className='row justify-content-center align-items-center pt-4'>
          {cardsData.map((data: ICardDataType) => (
            <div key={data.description} className='mb-3 mb-lg-4 col-9 col-md-5 col-lg-3'>
              <CardStyle className='bg-white rounded-2 mb-0 justify-content-center'>
                <div className='mb-3'>
                  <OrangeIcon size='MD' color='#FF7A00' icon={data.icon} />
                </div>
                <h3 className='fs-20 lg-25 fw-600 text-grayscale--500 mb-2 my-md-3'>{data.title}</h3>
                <p className='fs-20 lh-25 text-grayscale--400 mt-3' dangerouslySetInnerHTML={{ __html: data.description }} />
              </CardStyle>
            </div>
          ))}
          <div className='w-100 col-12 col-md-5 col-lg-4 d-flex justify-content-center align-items-center'>
            {width > WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-2'
                title='Garantir agora'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Vantagens em ser assinante',
                    element_action: 'click button',
                    element_name: 'Garantir agora',
                  })
                }}
              >
                Garantir agora
              </button>
            ) : (
              <a
                href={oneLink}
                target='_blank'
                rel='noreferrer'
                className='btn text-white btn-orange--extra text-none mt-2'
                title='Garantir agora'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Vantagens em ser assinante',
                    element_action: 'click button',
                    element_name: 'Garantir agora',
                    redirect_url: oneLink,
                  })
                }}
              >
                Garantir agora
              </a>
            )}
          </div>
        </div>
      </div>
    </VantagensSection>
  )
}

export default Vantagens
